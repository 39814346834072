import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { TModal } from './types.d';

export const TransitionsModal: React.FC<TModal> = ({
  state = false,
  title = '',
  children,
  width,
  height,
  overflow,
  handleCloseModal,
}) => {
  const [open, setOpen] = useState(state);

  // const hide = useCallback(() => {
  //   setOpen(false);
  //   handleCloseModal(false);
  // }, [handleCloseModal]);

  useEffect(() => {
    setOpen(state);
  }, [state]);

  const style = {
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      display: 'block',
      transform: 'translate(-50%, -50%)',
      width: width || 400,
      height: height || 400,
      bgcolor: 'background.paper',
      borderRadius: '5px',
      p: 3,
      border: 'none',
      outline: 'none',
    },
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        sx={{ wordWrap: 'break-word', height: 'auto' }}
        open={open}
        // onClose={hide}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <Box sx={style.modal}>
            <Box sx={{ width: '99%', height: '101%', overflow: overflow || 'auto' }}>
              <CloseIcon
                onClick={() => {
                  setOpen(false);
                  handleCloseModal(false);
                }}
                sx={{ float: 'right', cursor: 'pointer' }}
              />
              <Typography id="transition-modal-title" variant="h6" component="h2">
                {title}
              </Typography>
              <Box sx={{ overflow, height: '100%', width: '100%' }}>{children}</Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};
