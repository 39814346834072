import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';
import AWS from 'aws-sdk';
import { useLocation } from 'react-router-dom';
import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios';
import * as XLSX from 'xlsx';
import { TAxiosBody, TAxiosParams } from './types';
import { brandUrls } from '../../../lib/vars';

moment.locale('es');

export const constructorName = (values: string[]) => values?.filter((x) => !!x)?.join(' ');

export const removeCurrency = (value: string | number): string =>
  value.toString().replace(/,/g, '');

export const toCurrency = (value: string | number, shouldRound = true): string => {
  const roundedValue = shouldRound ? Math.round(value as number) : value;
  const splitValue = roundedValue?.toString()?.split('.');

  if (splitValue?.length > 1) {
    return [
      removeCurrency(splitValue[0]?.toString())?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
      splitValue[1],
    ].join(',');
  }
  return removeCurrency(roundedValue?.toString())?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

/**
 * Transforma una cantidad de números en formato dinero
 * @version 0.0.1
 * @param {number} value valor en números
 * @param {boolean} shouldRound indica si el valor debe redondearse antes de formatear
 * @return {string} valor formateado como dinero
 */
export const numberFormat = (value: string | number, shouldRound = true): string => {
  if (value) {
    return toCurrency(value || 0, shouldRound);
  }
  if (Number.isNaN(value as number)) return '';
  return '0';
};
/**
 * Validación de Email
 * @version 0.0.1
 */
export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
/**
 * Validación de solo Recibir letras
 * @version 0.0.1
 */
export const validateLetters = (value: string): boolean => {
  const lettersRegex = /^[A-Za-zñáéíóúÁÉÍÓÚÑ\s]+$/;
  return lettersRegex.test(value);
};

/**
 *
 * @param {Object} data objeto a filtrar
 * @param {Array} filters array a comparar o claves del objeto a excluir
 * @return {Object} devuelve un objeto con los datos filtrados
 */
/* export const filterKeyObject = (data: any, filters: Array<string>) => {
  let values = {};

  Object.keys(data)?.forEach((elem: string) => {
    let coincidence = true;
    if (filters.find((x) => x === elem)) coincidence = false;
    if (coincidence) values = { ...values, [elem]: data[elem] };
  });

  return values;
}; */

/**
 * Filtra un objeto eliminando las claves especificadas
 * @version 0.0.1
 * @param {Record<string, any>} data objeto a filtrar
 * @param {string[]} filters array de claves del objeto a excluir
 * @return {Record<string, any>} devuelve un objeto con los datos filtrados
 */
export const filterKeyObject = (
  data: Record<string, any>,
  filters: string[]
): Record<string, any> => {
  const values: Record<string, any> = {};
  Object?.keys(data || {})?.forEach((elem: string) => {
    const value = data[elem];
    if (value !== null && value !== undefined && filters.indexOf(elem) === -1) {
      values[elem] = value;
    }
  });

  return values;
};

// ESTO NO VA NO SIRVEEE

// /**
//  * Calcula el dígito de verificación
//  * @version 0.0.1
//  * @param {string} value valor en números
//  * @return {numeric} el dígito de verificación
//  */
// export const calculateCheckDigit = (value: string | number) => {
//   // variables necesarias
//   let nit = `${value}`;
//   const vpri = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];

//   // Se limpia el Nit
//   nit = nit.replace(/\s/g, ''); // Espacios
//   nit = nit.replace(/,/g, ''); // Comas
//   nit = nit.replace(/\./g, ''); // Puntos
//   nit = nit.replace(/-/g, ''); // Guiones

//   // Se valida el nit
//   if (!Number(nit)) return '';

//   // Procedimiento
//   let x = '0';
//   let y = '0';
//   let i = 0;
//   const z = nit.length;

//   for (i = 0; i < z; i++) {
//     y = nit.substring(i, 1);

//     x += Number(y) * vpri[z - i];
//   }

//   y = (Number(x) % 11).toString();

//   return Number(y) > 1 ? 11 - Number(y) : Number(y);
// };

/*
 * Transforma un número en formato de teléfono
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const phoneFormat = (value: string) =>
  !!value && parsePhoneNumber(`${value}`, 'US')?.formatNational();

/**
 * Transforma una cadena de caracteres a todos mayúscula
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato en teléfono
 */
export const upperCase = (value: string) => `${value || ''}`.toUpperCase();

/**
 * Transforma una fecha en ingles a formato español
 * @version 0.0.1
 * @param {string} value valor en números
 * @return {string} nuevo formato de fecha
 */
export const dateFormat = (value: string) => value && moment(value)?.format('DD-MM-YYYY');

/** busca los parámetros en la ruta
 * @return {Function} para poder llamar los parámetros
 */
export const useQueryParam = () => new URLSearchParams(useLocation().search);

/** busca los parámetros en la ruta
 * @return {Function} para poder llamar los parámetros
 */
export const getCreditStage = (state: number) => {
  let stage = '';
  switch (Number(state)) {
    case 0:
      stage = 'Rechazado';
      break;
    case 1:
      stage = 'Finalizado';
      break;
    case 2:
      stage = 'Solicitud';
      break;
    case 3:
      stage = 'Aprobación';
      break;
    case 4:
      stage = 'Documentos';
      break;
    case 5:
      stage = 'Espera de Desembolso';
      break;
    case 6:
      stage = 'Desembolsado';
      break;
    case 7:
      stage = 'Espera de Pagaduría';
      break;
    case 8:
      stage = 'Comité';
      break;
    case 9:
      stage = 'Supervisión';
      break;
    case 10:
      stage = 'Información adicional';
      break;
    case 12:
      stage = 'Desembolso parcial';
      break;
    default:
      break;
  }
  return stage;
};
/**
 * function debounce
 * @param { function } func funciona que ejecuta el debounce
 * @return { function } función que limpia el intervalo
 */
export const debounce = (func: any) => {
  const interval = setTimeout(() => {
    func();
  }, 500);
  return interval && clearInterval(interval);
};

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean} lower Whether all other letters should be lowercase
 * @returns {string} retorna string
 */
export const capitalize = (str: string, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

/**
 * Transforma un Tipo File a Base64 modo Async
 * @version 0.0.1
 * @async
 * @param {object} file tipo file
 * @return {string} base64
 */
export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * transforma todo de base64 a arraybuffer
 * @version 0.0.1
 * @param {string} base64 valor
 * @return {array} array en bytes
 */
export const base64ToArrayBuffer = (base64: any) => {
  if (!base64) return false;
  const binaryString = window.atob(base64.substring(base64.indexOf('base64,') + 7, base64.length));
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString?.charCodeAt(i);
  }
  return bytes;
};

export const newBase64ToArrayBuffer = (base64: string) => {
  try {
    // Verifica si la entrada base64 es válida y no está vacía
    if (!base64) {
      throw new Error('La cadena Base64 está vacía o no es válida.');
    }

    // Elimina cualquier encabezado "base64," si está presente
    const base64Data = base64.includes('base64,') ? base64.split('base64,')[1] : base64;

    // Decodifica la cadena Base64 en un ArrayBuffer
    const binaryString = window.atob(base64Data);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes.buffer;
  } catch (error) {
    // Puedes elegir lanzar una excepción aquí si lo prefieres
    return null; // O devuelve null u otro valor que indique un error
  }
};

/**
 * Busca la extension del archivo
 * @version 0.0.1
 * @param {string} filename nombre del archivo con la extension
 * @return {string} nombre de la extension
 */
export const extFile = (filename: string) => {
  return /[.]/.exec(filename) ? /[^.]+$/?.exec(filename)?.[0] : undefined;
};

/**
 * Se conecta a Aws3
 * @version 0.0.1
 * @return {boolean} la conexión
 */
export const AWS3 = () =>
  new AWS.S3({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_AWS,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY_AWS,
  });

/**
 * Busca un documento en S3
 * @param {String} Key variable de búsqueda
 * @return {String} base64 del documento
 */
export const getFileUrlS3 = async (Key: string) => {
  const client = brandUrls();
  const S3 = AWS3();
  const params = { Bucket: client.bt || 'pruebaawow', Prefix: Key };
  try {
    const response = await S3.listObjectsV2(params).promise();

    const objectKeys = response?.Contents?.map((obj: AWS.S3.Object) => obj.Key);
    const exists = objectKeys?.includes(Key) ?? false;
    if (!exists) return null;
    const res = await S3.getSignedUrlPromise('getObject', {
      Bucket: client.bt || 'pruebaawow',
      Key,
    });
    return res;
  } catch (error) {
    return undefined;
  }
};

/**
 * guarda un documento en S3
 * @param {String} Key variable de búsqueda
 * @param {Array} Body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const putFileS3 = async (
  Key: string,
  Body: undefined | boolean | Uint8Array | ArrayBuffer
) => {
  const client = brandUrls();
  const S3 = AWS3();
  const res = await S3.putObject({
    Bucket: client.bt || 'pruebaawow',
    Key,
    Body,
  })
    .promise()
    .catch(() => undefined);
  return res;
};
export const arrayBufferTobase64 = (arrayBuffer) => {
  let base64 = '';
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes?.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a;
  let b;
  let c;
  let d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i += 3) {
    // Combine the three bytes into a single integer
    // eslint-disable-next-line no-bitwise
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    // eslint-disable-next-line no-bitwise
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    // eslint-disable-next-line no-bitwise
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    // eslint-disable-next-line no-bitwise
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    // eslint-disable-next-line no-bitwise
    d = chunk & 63; // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder === 1) {
    chunk = bytes[mainLength];

    // eslint-disable-next-line no-bitwise
    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    // eslint-disable-next-line no-bitwise
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += `${encodings[a]}${encodings[b]}==`;
  } else if (byteRemainder === 2) {
    // eslint-disable-next-line no-bitwise
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    // eslint-disable-next-line no-bitwise
    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    // eslint-disable-next-line no-bitwise
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    // eslint-disable-next-line no-bitwise
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += `${encodings[a]}${encodings[b]}${encodings[c]}=`;
  }

  return base64;
};

export const blobToBase64 = async (blob) => {
  const base64Data = await new Promise((resolve) => {
    const reader: any = new FileReader();
    reader.onload = () => resolve(reader?.result?.split(',')[1]);
    reader.readAsDataURL(blob);
  });
  return base64Data;
};

/**
 * convertir blob a base64
 * @param {String} Key variable de búsqueda
 * @param {Array} Body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const blobUrlToBase64 = async (blobUrl) => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  const base64Data = await new Promise((resolve) => {
    const reader: any = new FileReader();
    reader.onload = () => resolve(reader?.result?.split(',')[1]);
    reader.readAsDataURL(blob);
  });
  return base64Data;
};

export const arrayBufferToBase64 = (arrayBuffer) => {
  // Convert ArrayBuffer to binary string
  let binaryString = '';
  const bytes = new Uint8Array(arrayBuffer);
  const length = bytes.byteLength;

  for (let i = 0; i < length; i++) {
    binaryString += String.fromCharCode(bytes[i]);
  }

  // Convert binary string to Base64
  const base64String = btoa(binaryString);

  return base64String;
};

export const constructorMoneyValue = (value: string) => {
  return `$ ${numberFormat(value)}`;
};
export const diffInDays = (to: moment.Moment, from: moment.Moment) => {
  // Convertimos las fechas a objetos Date
  const dataTo = to.toDate();
  const dateFrom = from.toDate();

  // Convertimos las fechas a días
  const daysTo = dataTo.getFullYear() * 360 + dataTo.getMonth() * 30 + dataTo.getDate();
  const daysFrom = dateFrom.getFullYear() * 360 + dateFrom.getMonth() * 30 + dateFrom.getDate();

  // Calculamos la diferencia en días
  const difference = Math.abs(daysFrom - daysTo);

  return difference;
};

export const numberFormatM = (param: number) => {
  let money = 0;
  let num: number | string = 0;
  let value = 0;
  let val = param;
  if (val >= 1000000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}000`);
    money += value;
    val -= parseFloat(`${num}000000000`);
  }

  if (val >= 100000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}00`);
    money += value;
    val -= parseFloat(`${num}00000000`);
  }

  if (val >= 10000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}0`);
    money += value;
    val -= parseFloat(`${num}0000000`);
  }

  if (val >= 1000000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`${num}`);
    money += value;
    val -= parseFloat(`${num}000000`);
  }
  if (val >= 100000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.${num}`);
    money += value;
    val -= parseFloat(`${num}00000`);
  }
  if (val >= 10000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.0${num}`);
    money += value;
    val -= parseFloat(`${num}0000`);
  }
  if (val >= 1000) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.00${num}`);
    money += value;
    val -= parseFloat(`${num}000`);
  }
  if (val >= 100) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.000${num}`);
    money += value;
    val -= parseFloat(`${num}00`);
  }
  if (val >= 10) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.0000${num}`);
    money += value;
    val -= parseFloat(`${num}0`);
  }
  if (val >= 1) {
    num = `${val}`.charAt(0);
    value = parseFloat(`0.00000${num}`);
    money += value;
    val -= parseFloat(`${num}`);
  }

  return money.toFixed(2);
};

/**
 * Función para consultar los datos de una API
 * @param {Any} method Método de búsqueda
 * @param {String} url Array de buffer del documento
 * @param {String} body Array de buffer del documento
 * @return {String} respuesta del servidor
 */
export const fetcher = async (
  method: string,
  url: string,
  header: AxiosRequestHeaders | Record<string, string | number | boolean>,
  body?: TAxiosBody,
  params?: TAxiosParams
): Promise<AxiosResponse> => {
  const requestBody = body?.request;
  const baseURL = brandUrls();
  const res: AxiosResponse = await axios({
    method,
    baseURL: `${baseURL?.bk}api/external/treasury${url}`,
    headers: header,
    params: params?.data,
    data: requestBody,
  });
  return res;
};
export const fetcherOss = async (
  method: string,
  url: string,
  header: AxiosRequestHeaders | Record<string, string | number | boolean>,
  body?: TAxiosBody,
  params?: TAxiosParams
): Promise<AxiosResponse> => {
  const requestBody = body?.request;
  const baseURL = brandUrls();
  const res: AxiosResponse = await axios({
    method,
    baseURL: `${baseURL?.ossado}${url}`,
    headers: header,
    params: params?.data,
    data: requestBody,
  });
  return res;
};

export const exportToExcel = (data: any, filename: string) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.xlsx`;
  a.click();

  URL.revokeObjectURL(url);
};

export const exportToTxt = (data: any, filename: string) => {
  const content = data
    .map((obj: any) =>
      Object.entries(obj)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ')
    )
    .join('\n');
  const blob = new Blob([content], {
    type: 'text/plain',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${filename}.txt`;
  a.click();
  URL.revokeObjectURL(url);
};

/**
 * Calcula el interés ajustable a la fecha
 * @return {number} Retorna valor del interés ajustable
 */
export const getVatAdj = (cValCre: number, cRatVal: number) => {
  // const vatAdj =
  //   ((cValCre * (cRatVal / 100)) / 30) *
  //   (Number(moment().daysInMonth()) - Number(moment().format('DD')) + 1);
  const vatAdj =
    cValCre *
      (1 + cRatVal / 100) **
        ((Number(moment().daysInMonth()) - Number(moment().format('DD'))) / 30) -
    cValCre;
  return Math.round(vatAdj) || 0;
};

/**
 * Calcula el interés ajustable a la fecha
 * @return {number} Retorna valor del interés ajustable
 */
export const getMethod = (cReTanking: number, cOptPay: number) => {
  if (cReTanking === 1 || cOptPay === 1) {
    if (cReTanking === 1) {
      return 'RETANQUEO';
    }
    return 'REFINANCIACIÓN';
  }
  return 'NORMAL';
};

export const getBase64UrlPdf = (base64String: string) => {
  const base64Data = base64String.split(',')[1];

  // Convierte la cadena Base64 en un arreglo de bytes
  const byteCharacters = window?.atob(base64Data);

  // Convierte los bytes en un arreglo de tipo unsigned 8 bits
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Crea un Blob a partir del arreglo de bytes
  const blob = new Blob([new Uint8Array(byteNumbers)], { type: 'application/pdf' });

  // Genera una URL Blob a partir del Blob
  const blobUrl = URL.createObjectURL(blob);

  return blobUrl;
};

/**
 * Función que devuelve los años hasta el actual desde uno inicial
 * @param initialYear Año de inicio
 * @returns years
 */
export const getYears = (initialYear: number) => {
  const currentYear = new Date()?.getFullYear();
  const yearsCount = currentYear - initialYear + 1;
  const years = new Array(yearsCount);

  for (let i = 0; i < yearsCount; i++) {
    const year = initialYear + i;
    years[i] = { value: year, label: year?.toString() };
  }

  return years;
};

export const fromLettersToNumber = (numString: number | string) => {
  const numAsString = String(numString);
  const numWithoutDots = numAsString.replace(/\./g, '');
  const num = parseFloat(numWithoutDots);
  return num;
};
